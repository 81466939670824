<template>
  <div></div>
</template>

<script>
import {getAuthToken, removeAuthToken} from "@/utils/auth.js";
import {createNamespacedHelpers} from "vuex";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  computed: {
    ...ModuleAuth.mapGetters(["getLoggedData"])
  },
  methods: {
    ...ModuleAuth.mapActions(["actLogout"]),
    ...ModuleAuth.mapMutations(["setLoginStatus"])
  },
  mounted() {
    if (getAuthToken()) {
      this.actLogout().then(() => {
        removeAuthToken();
        this.setLoginStatus(false);
        this.$router.replace("/");
      });
    } else {
      this.$router.replace("/");
    }
  }
};
</script>

<style></style>
